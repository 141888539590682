// @flow

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { filter, get } from 'lodash-es'
import { useLocation } from 'react-router-dom'
import type { Match } from 'react-router-dom'
import type { Node } from 'react'

import {
  getActivityTypes,
  getActivityPriorities,
} from '../../core/api/api.pipeline'
import { getInvoiceFields } from '../../core/api/api.budgetPlan'
import ActivityModal from '../../components/Activities/ActivityModal'
import InvoicesModal from '../../components/BudgetPlan/Invoices/InvoicesModal/InvoicesModal'
import EmptyList from '../../components/EmptyList'
import RequestViewInfo from './RequestViewInfo'
import RequestBody from '../../components/Request/RequestBody'
import RequestFiles from '../../components/Request/RequestFiles'
import RequestUpdates from '../../components/Request/RequestUpdates'
import RequestFeedback from '../../components/Request/RequestFeedback'
import AddCheckListPopUp from '../../components/modals/AddCheckListPopUp'
import Checklist from '../../components/Checklist/Checklist'
import { clearBr } from '../../utils/text'
import {
  cancelChecklist,
  createChecklistInit,
  deleteChecklistInit,
  hideModal,
  openChecklistsPopUp,
  resetInitialState,
} from './RequestView.actions'
import { REQUEST_TYPES_WITHOUT_RATING } from '../../constants'
import { initRequest, updateBody } from './RequestView.actions'
import Modal from '../../components/Modal'
import { REQUEST_VIEW_UPDATE_FILES } from './RequestView.constants'
import NewTabRouter from '../../components/NewTabRouter/NewTabRouter'
import RequestActivity from './RequestActivity'
import { getUser } from '../../utils/commonSelectors'
import { isDwellerUser, isStaffUser, isWorkerUser } from '../../utils/utils'
import { Header, SimpleBlock } from '../../components/Block'
import { usePrevious } from '../../hooks/usePrevious'
import Loader from '../../components/Loader'
import RequestRequests from './RequestRequests'
import NewsList from '../../containers/NewsList'
import RequestMassEditModal from './RequestMassEditModal'

type Props = {
  match: Match,
}

const NewRequestView = (props: Props): Node => {
  const {
    match: {
      params: { outbound, requestID },
    },
  } = props

  const { t } = useTranslation('Request')

  const user = useSelector(getUser)

  const isDweller = isDwellerUser(user)
  const isWorker = isWorkerUser(user)

  const {
    data: requestData,
    working,
    massEditModal,
    checklists,
    isChecklistsPopUpOpen,
    relatedRequestsCount,
    callbackOnUpdate,
  } = useSelector(state => state.requestView)

  const dispatch = useDispatch()

  const [modal, setModal] = useState(null)
  const [activity, setActivity] = useState(null)
  const [isModalOpen, setModalOpen] = useState(false)
  const [activityTypes, setActivityTypes] = useState(null)
  const [activityPriorities, setActivityPriorities] = useState(null)
  const [isReloading, setReloading] = useState(false)
  const [automationLoading, setAutomationLoading] = useState(false)
  const [pipelineSwitched, setPipelineSwitched] = useState(false)
  const [filesReloading, setFilesReloading] = useState(false)
  const [postsReloading, setPostsReloading] = useState(false)

  const [invoiceFileId, setInvoiceFileId] = useState(null)
  const [invoiceFields, setInvoiceFields] = useState(null)
  const [invoicesModal, setInvoicesModal] = useState(false)

  const [phaseId, setPhaseId] = useState(null)
  const [newPhaseId, setNewPhaseId] = useState(null)

  useEffect(() => {
    if (isChecklistsPopUpOpen) {
      setModal(
        <AddCheckListPopUp
          onHide={() => {
            setModal(null)
            dispatch(hideModal())
          }}
          onCreate={handleCreateCheckList}
        />
      )
    }
  }, [isChecklistsPopUpOpen])

  useEffect(() => {
    if (massEditModal) {
      setModal(
        <RequestMassEditModal
          callbackOnUpdate={callbackOnUpdate}
          count={relatedRequestsCount}
          requestId={requestID}
          onClose={() => {
            setModal(null)
            dispatch(hideModal())
          }}
        />
      )
    }
  }, [massEditModal])

  const location = useLocation()

  useEffect(() => {
    if (requestID) {
      dispatch(initRequest(requestID, outbound))
      setModal(null)
    }

    return function cleaup() {
      dispatch(resetInitialState())
    }
  }, [requestID])

  const prevLocation = usePrevious(location)

  let hasUnreadMessages =
    !requestData?.public_chatroom_read || !requestData?.dweller_thread_read

  if (!isDweller && !outbound) {
    hasUnreadMessages ||=
      !requestData?.private_chatroom_read ||
      !requestData?.manager_thread_read ||
      !requestData?.provider_thread_read
  }

  useEffect(() => {
    if (
      requestID &&
      prevLocation &&
      (prevLocation?.pathname.includes('address') ||
        prevLocation?.pathname.includes('activitiy') ||
        prevLocation?.pathname.includes('tasks') ||
        prevLocation?.pathname.includes('documents')) &&
      prevLocation?.pathname !== location.pathname &&
      hasUnreadMessages
    ) {
      dispatch(initRequest(requestID, outbound))
    }
  }, [location])

  useEffect(() => {
    if (isModalOpen && !activityTypes && !activityPriorities) {
      let ActivityType = get(activity, 'type.id')
      getActivityTypes().then(data => {
        let enableActivityTypes = filter(
          data.results.objects,
          item => item.enabled || item.id === ActivityType
        )
        setActivityTypes(enableActivityTypes)
      })

      getActivityPriorities().then(data => {
        setActivityPriorities(data.results.objects)
      })
    }
  }, [isModalOpen])

  useEffect(() => {
    if (invoicesModal && !invoiceFields) {
      getInvoiceFields(requestData?.id).then(data => {
        setInvoiceFields(data.invoice_fields)
      })
    }
  }, [invoicesModal])

  useEffect(() => {
    if (activity) {
      setModalOpen(true)
    }
  }, [activity])

  useEffect(() => {
    if (requestData) {
      setFilesReloading(!working)
      setPostsReloading(!working)
    }
  }, [requestData, working])

  if (!requestData) {
    return <Loader type='big' text={false} />
  }

  const {
    id,
    request_no,
    address_obj,
    title,
    permissions,
    permissions: {
      can_view_checklists: canViewChecklists,
      can_edit_checklists: canEditChecklists,
    },
    status_obj: { id: statusId },
    file_objs: fileObjs,
    directory: { id: directoryId },
    soft_archived,
  } = requestData

  const handleUpdateBody = ({ text, title }) =>
    dispatch(
      updateBody(id, {
        title,
        text: clearBr(text),
      })
    )

  const realChecklists = checklists.filter(checklist => !checklist.archived)

  const handleDeleteChecklist = checklistId =>
    dispatch(deleteChecklistInit(checklistId))

  const handleCreateCheckList = (name, templateId) => {
    dispatch(createChecklistInit(id, name, templateId))

    setModal(null)
  }

  const handleCancelCheckList = () => dispatch(cancelChecklist())

  const showCreateCheckListPopUp = () => dispatch(openChecklistsPopUp())

  const updateFiles = files => {
    dispatch({
      type: REQUEST_VIEW_UPDATE_FILES,
      id: id,
      files,
    })
  }

  const openAddToInvoice = fileId => {
    setInvoiceFileId(fileId)
    setInvoicesModal(true)
  }

  let mainTabs = [
    { value: 'address', label: t('BodyTabTitle') },
    { value: 'activitiy', label: t('ActivitiesTabTitle') },
  ]

  if (canViewChecklists) {
    mainTabs.push({ value: 'tasks', label: t('TasksTabTitle') })
  }

  mainTabs.push({ value: 'documents', label: t('DocumentsTabTitle') })

  if (!outbound && isStaffUser(user)) {
    mainTabs.push({ value: 'requests', label: t('ConnectedRequestsTabTitle') })
  }

  if (!outbound && !REQUEST_TYPES_WITHOUT_RATING.includes(statusId)) {
    mainTabs = mainTabs.concat({
      value: 'feedback',
      label: t('FeedbackTabTitle'),
    })
  }

  if (!outbound && isStaffUser(user)) {
    mainTabs.push({ value: 'posts', label: t('Posts') })
  }

  const handleClose = () => setModalOpen(false)
  const reloadPipline = () => {
    if (activity?.active_automation_count > 0) {
      setAutomationLoading(true)
    }

    setReloading(true)
  }

  return (
    <>
      <RequestViewInfo
        outbound={outbound}
        requestData={requestData}
        relatedRequestsCount={relatedRequestsCount}
        working={working}
        setModal={setModal}
        setPipelineSwitched={setPipelineSwitched}
        isDweller={isDweller}
      />
      <NewTabRouter tabs={mainTabs}>
        <RequestBody
          address
          outbound={outbound}
          requestData={requestData}
          working={working}
          onSave={handleUpdateBody}
        />
        {canViewChecklists && (
          <div tasks>
            {realChecklists.map(({ id, name, justAdded }) => (
              <Checklist
                key={id}
                checklistId={id}
                name={name}
                shouldScrollIntoView={justAdded}
                isEditable={canEditChecklists}
                cancelChecklist={handleCancelCheckList}
                onDeleteChecklist={handleDeleteChecklist}
              />
            ))}
            {!realChecklists.length && (
              <SimpleBlock style={{ marginTop: 0 }}>
                <Header notCollapse header>
                  {t('Checklist')}
                </Header>
                <EmptyList
                  embedded
                  btnText={t('AddChecklist')}
                  canAdd={canEditChecklists}
                  icon='checklist'
                  title={t('EmptyChecklists')}
                  onClick={showCreateCheckListPopUp}
                />
              </SimpleBlock>
            )}
          </div>
        )}
        <RequestActivity
          activitiy
          setActivity={setActivity}
          isDweller={isDweller}
          isWorker={isWorker}
          requestId={id}
          directoryId={directoryId}
          requestNo={request_no}
          requestTitle={title}
          isReloading={isReloading}
          setReloading={setReloading}
          setModalOpen={setModalOpen}
          outbound={outbound}
          phaseId={phaseId}
          setPhaseId={setPhaseId}
          newPhaseId={newPhaseId}
          setNewPhaseId={setNewPhaseId}
          buildingId={requestData?.building}
          flatId={requestData?.flat}
          files={fileObjs}
          automationLoading={automationLoading}
          setAutomationLoading={setAutomationLoading}
          pipelineSwitched={pipelineSwitched}
          setPipelineSwitched={setPipelineSwitched}
        />
        <RequestFiles
          documents
          isArchived={soft_archived}
          isDweller={isDweller}
          outbound={outbound}
          requestId={id}
          directoryId={directoryId}
          permissions={permissions}
          working={filesReloading}
          setReloading={setReloading}
          onSave={updateFiles}
          onAddToInvoice={openAddToInvoice}
        />
        {!outbound && isStaffUser(user) && (
          <RequestRequests
            requests
            params={{ request: id }}
            init={{ request: id }}
          />
        )}
        {!outbound && !REQUEST_TYPES_WITHOUT_RATING.includes(statusId) && (
          <RequestFeedback feedback />
        )}
        {!outbound && isStaffUser(user) && (
          <div posts>
            <NewsList
              additionalQueryParams={{ request: id }}
              emptyBoxTitle={t('Posts')}
              postsReloading={postsReloading}
            />
          </div>
        )}
      </NewTabRouter>
      <RequestUpdates
        isReloading={isReloading}
        outbound={outbound}
        requestData={requestData}
        setActivity={setActivity}
        setReloading={setReloading}
      />
      <Modal
        isOpen={!!modal}
        style={{ content: { minWidth: '482px' } }}
        onRequestClose={() => {
          setModal(null)
          dispatch(hideModal())
        }}
      >
        {modal}
      </Modal>
      {isModalOpen && activityPriorities && activityTypes && (
        <ActivityModal
          activity={activity}
          isModalOpen={isModalOpen}
          request={{
            id,
            title,
            request_no,
          }}
          building={
            address_obj ? { address: address_obj.value } : { address: '-' }
          }
          setActivity={setActivity}
          setModalOpen={setModalOpen}
          load={reloadPipline}
          phaseId={phaseId}
          setPhaseId={setPhaseId}
          setNewPhaseId={setNewPhaseId}
          activityTypes={activityTypes}
          activityPriorities={activityPriorities}
          setActivityTypes={setActivityTypes}
          setActivityPriorities={setActivityPriorities}
          onClose={handleClose}
        />
      )}
      {invoicesModal && invoiceFields && (
        <InvoicesModal
          requestId={id}
          fields={invoiceFields}
          fileId={invoiceFileId}
          setInvoiceFields={setInvoiceFields}
          onClose={() => setInvoicesModal(false)}
        />
      )}
    </>
  )
}

export default NewRequestView
