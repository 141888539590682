// @flow

export const REQUEST_VIEW_INITIATING = 'REQUEST_VIEW_INITIATING'
export const REQUEST_VIEW_INITIATED = 'REQUEST_VIEW_INITIATED'
export const REQUEST_VIEW_UPDATE_INFO = 'REQUEST_VIEW_UPDATE_INFO'
export const REQUEST_VIEW_UPDATE_BODY = 'REQUEST_VIEW_UPDATE_BODY'
export const REQUEST_VIEW_UPDATED = 'REQUEST_VIEW_UPDATED'
export const REQUEST_VIEW_ERROR = 'REQUEST_VIEW_ERROR'

export const GET_CHECKLISTS_INIT = 'GET_CHECKLISTS_INIT'
export const GET_CHECKLISTS_SUCCESS = 'GET_CHECKLISTS_SUCCESS'
export const GET_CHECKLISTS_ERROR = 'GET_CHECKLISTS_ERROR'

export const CREATE_CHECKLIST_INIT = 'CREATE_CHECKLIST_INIT'
export const CREATE_CHECKLIST_SUCCESS = 'CREATE_CHECKLIST_SUCCESS'
export const CREATE_CHECKLIST_ERROR = 'CREATE_CHECKLIST_ERROR'
export const CANCEL_CHECKLIST = 'CANCEL_CHECKLIST'

export const DELETE_CHECKLIST_INIT = 'DELETE_CHECKLIST_INIT'
export const DELETE_CHECKLIST_SUCCESS = 'DELETE_CHECKLIST_SUCCESS'
export const DELETE_CHECKLIST_ERROR = 'DELETE_CHECKLIST_ERROR'

export const UPDATE_DUE_DATE_INIT = 'UPDATE_DUE_DATE_INIT'
export const UPDATE_DUE_DATE_SUCCESS = 'UPDATE_DUE_DATE_SUCCESS'
export const UPDATE_DUE_DATE_ERROR = 'UPDATE_DUE_DATE_ERROR'

export const RESET_INITIAL_STATE = 'RESET_INITIAL_STATE'

export const REQUEST_VIEW_SHOW_MODAL = 'REQUEST_VIEW_SHOW_MODAL'
export const REQUEST_VIEW_HIDE_MODAL = 'REQUEST_VIEW_HIDE_MODAL'

export const REQUEST_VIEW_UPDATE_LABELS = 'REQUEST_VIEW_UPDATE_LABELS'
export const REQUEST_VIEW_LABEL_UPDATED = 'REQUEST_VIEW_LABEL_UPDATED'

export const REQUEST_VIEW_SHOW_MODAL_DUE_DATE =
  'REQUEST_VIEW_SHOW_MODAL_DUE_DATE'
export const REQUEST_VIEW_SHOW_MODAL_LABELS = 'REQUEST_VIEW_SHOW_MODAL_LABELS'
export const REQUEST_VIEW_SHOW_MODAL_EDIT_LABELS =
  'REQUEST_VIEW_SHOW_MODAL_EDIT_LABELS'
export const REQUEST_VIEW_SHOW_MODAL_CHECKLISTS =
  'REQUEST_VIEW_SHOW_MODAL_CHECKLISTS'
export const REQUEST_VIEW_SHOW_MODAL_REMOVE = 'REQUEST_VIEW_SHOW_MODAL_REMOVE'

export const REQUEST_VIEW_OPEN_MASS_EDIT_MODAL =
  'REQUEST_VIEW_OPEN_MASS_EDIT_MODAL'

export const EDIT_FROM_WIDGET = 'EDIT_FROM_WIDGET'
export const CLEAR_EDIT_FROM_WIDGET = 'CLEAR_EDIT_FROM_WIDGET'

export const REQUEST_VIEW_UPDATE_FILES = 'REQUEST_VIEW_UPDATE_FILES'
export const REQUEST_VIEW_UPDATE_THREAD_COUNTERS =
  'REQUEST_VIEW_UPDATE_THREAD_COUNTERS'

export type Checklist = {
  id: number,
  name: string,
}
