// @flow

import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import type { Node } from 'react'

import {
  getMassEditRequestFields,
  updateRelatedRequests,
  asyncMassActionTask,
} from '../../../core/api/api.request'

import { initRequest } from '../../../containers/RequestView/RequestView.actions'

import Modal from '../../../components/Modal'
import ModalBody from '../../../components/Modal/ModalBody'
import ModalRow from '../../../components/Modal/ModalRow'
import ModalCloseButton from '../../../components/Modal/ModalCloseButton'
import ModalHead from '../../../components/Modal/ModalHead'
import ModalButtons from '../../../components/Modal/ModalButtons'
import Button from '../../../components/Button'
import Checkbox from '../../../components/Checkbox'
import { useOverflow } from '../../../hooks'

import Warning from '../../../components/Warning'

import styles from './RequestMassEditModal.module.scss'

import RelatedRequestsModal from '../RelatedRequestsModal'
import RequestField from './RequestField'

const CHECK_UPDATE_TASK_INTERVAL = 2000
const STATUS_UPDATE_COMPLETE = 2

const RequestMassEditModal = (props): Node => {
  const { callbackOnUpdate, count, requestId, onClose } = props

  const [payloadArr, setPayloadArr] = useState([])
  const [fields, setFields] = useState([])
  const [exclude_archived, setExcludeArchived] = useState(false)
  const [working, setWorking] = useState(false)

  const { t } = useTranslation('Request')
  const dispatch = useDispatch()

  useOverflow()

  const [relatedModal, setRelatedModal] = useState(null)

  useEffect(() => {
    getMassEditRequestFields().then(data => setFields(data.fields))
  }, [])

  const openRelatedRequestsModal = () => {
    setRelatedModal(
      <RelatedRequestsModal
        requestId={requestId}
        onClose={() => setRelatedModal(null)}
      />
    )
  }

  const newEmptyField = {
    name: null,
    command: null,
    value: null,
  }

  const handleAddField = () => {
    let newPayloadArr = [...payloadArr, newEmptyField]
    setPayloadArr(newPayloadArr)
  }

  const handleChangeField = (i, key, value) => {
    let newPayloadArr = payloadArr
    newPayloadArr[i] = {
      ...newPayloadArr[i],
      [key]: value,
    }
    setPayloadArr([...newPayloadArr])
  }

  const handleRemoveField = i => {
    let newPayloadArr = payloadArr
    newPayloadArr.splice(i, 1)
    setPayloadArr([...newPayloadArr])
  }

  const handleChangeExclude = e => setExcludeArchived(e.currentTarget.checked)

  const handleUpdate = () => {
    setWorking(true)
    let payload = {}
    payloadArr.forEach(({ name, command, value }) => {
      payload[name] = {
        command,
        value,
      }
    })
    updateRelatedRequests(requestId, {
      payload,
      exclude_archived,
    }).then(data => {
      checkUpdateTask(data.task_id)
    })
  }

  const checkUpdateTask = taskId => {
    asyncMassActionTask(taskId).then(data => {
      if (data.status !== STATUS_UPDATE_COMPLETE) {
        setTimeout(() => checkUpdateTask(taskId), CHECK_UPDATE_TASK_INTERVAL)
      } else {
        setWorking(false)
        dispatch(initRequest(requestId))
        callbackOnUpdate && callbackOnUpdate()
        onClose()
      }
    })
  }

  const validPayload = () => {
    if (!payloadArr.length) {
      return false
    }

    return !payloadArr.some(
      item =>
        !item.command ||
        (item.command !== 'clear' &&
          (!item.value || (Array.isArray(item.value) && !item.value.length)))
    )
  }

  return (
    <>
      <Modal
        isOpen
        style={{
          content: { minWidth: '732px', maxWidth: '732px' },
        }}
        onRequestClose={onClose}
      >
        <ModalHead title={t('MassEditRequestsTitle')} />
        <ModalCloseButton onClose={onClose} />
        <ModalBody
          style={{
            minHeight: '430px',
            maxHeight: '604px',
            overflow: 'auto',
            padding: '2px',
          }}
        >
          <ModalRow>
            <Warning
              noArrow
              link={t('ViewList')}
              text={t('ReviewRelatedRequests', { count })}
              boxClassName={styles.warningBox}
              className={styles.warning}
              onClick={openRelatedRequestsModal}
            />
          </ModalRow>
          <ModalRow>
            <Warning
              noArrow
              text={t('LongOperationWarning')}
              boxClassName={styles.warningBox}
              className={styles.warning}
            />
          </ModalRow>
          {payloadArr.map((item, i) => (
            <RequestField
              index={i}
              key={i}
              field={item}
              fields={fields}
              payloadArr={payloadArr}
              onChange={(key, value) => handleChangeField(i, key, value)}
              onRemove={() => handleRemoveField(i)}
            />
          ))}
        </ModalBody>
        <ModalButtons>
          <Button.Save
            disabled={!validPayload()}
            working={working}
            onClick={handleUpdate}
          >
            {t('Common:Ok')}
          </Button.Save>
          <Button.Cancel onClick={onClose}>{t('Common:Cancel')}</Button.Cancel>
          {payloadArr.length < fields.length && (
            <Button.Add
              className={styles.addButton}
              title={t('AddMoreFields')}
              onClick={handleAddField}
            />
          )}
          <Checkbox
            className={styles.excludeCheckbox}
            checked={exclude_archived}
            text={t('ExcludeArchived')}
            onChange={handleChangeExclude}
          />
        </ModalButtons>
      </Modal>
      {relatedModal && (
        <Modal
          isOpen
          style={{
            content: { minWidth: '732px', maxWidth: '732px' },
          }}
          onRequestClose={() => setRelatedModal(null)}
        >
          {relatedModal}
        </Modal>
      )}
    </>
  )
}

export default RequestMassEditModal
