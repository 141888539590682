// @flow

import {
  GET_CHECKLISTS_INIT,
  GET_CHECKLISTS_SUCCESS,
  GET_CHECKLISTS_ERROR,
  CREATE_CHECKLIST_INIT,
  CREATE_CHECKLIST_SUCCESS,
  CREATE_CHECKLIST_ERROR,
  CANCEL_CHECKLIST,
  DELETE_CHECKLIST_INIT,
  DELETE_CHECKLIST_SUCCESS,
  DELETE_CHECKLIST_ERROR,
  UPDATE_DUE_DATE_INIT,
  UPDATE_DUE_DATE_SUCCESS,
  UPDATE_DUE_DATE_ERROR,
  RESET_INITIAL_STATE,
  REQUEST_VIEW_OPEN_MASS_EDIT_MODAL,
  REQUEST_VIEW_SHOW_MODAL_CHECKLISTS,
  REQUEST_VIEW_SHOW_MODAL,
  REQUEST_VIEW_HIDE_MODAL,
  REQUEST_VIEW_UPDATE_LABELS,
  REQUEST_VIEW_UPDATE_INFO,
  REQUEST_VIEW_INITIATING,
  REQUEST_VIEW_UPDATE_BODY,
  REQUEST_VIEW_UPDATE_FILES,
} from './RequestView.constants'
import type { Checklist } from './RequestView.constants'

type ResetInitialStateAction = { type: string }
type GetChecklistsInitAction = { requestId: number, type: string }
type GetChecklistsSuccessAction = { checklists: Array<Checklist>, type: string }
type GetChecklistsErrorAction = { error: string, type: string }
type CreateChecklistInitAction = {
  name: string,
  requestId: number,
  templateId?: number,
  type: string,
}
type CreateChecklistSuccessAction = {
  checklist: Checklist,
  type: string,
}
type CancelChecklistAction = {
  type: string,
}
type CreateChecklistErrorAction = { error: string, type: string }
type DeleteChecklistInitAction = { checklistId: number, type: string }
type DeleteChecklistSuccessAction = { checklistId: number, type: string }
type DeleteChecklistErrorAction = { error: string, type: string }
type UpdateDueDateInitAction = {
  dueDate: string,
  requestId: number,
  type: string,
}
type UpdateDueDateSuccessAction = { request: Object, type: string }
type UpdateDueDateErrorAction = { error: string, type: string }

export function resetInitialState(): ResetInitialStateAction {
  return {
    type: RESET_INITIAL_STATE,
  }
}

export function getChecklistsInit(requestId: number): GetChecklistsInitAction {
  return {
    type: GET_CHECKLISTS_INIT,
    requestId,
  }
}

export function getChecklistsSuccess(
  checklists: Array<Checklist>
): GetChecklistsSuccessAction {
  return {
    type: GET_CHECKLISTS_SUCCESS,
    checklists,
  }
}

export function getChecklistsError(error: string): GetChecklistsErrorAction {
  return {
    type: GET_CHECKLISTS_ERROR,
    error,
  }
}

export function createChecklistInit(
  requestId: number,
  name: string,
  templateId?: number
): CreateChecklistInitAction {
  return {
    type: CREATE_CHECKLIST_INIT,
    requestId,
    name,
    templateId,
  }
}

export function createChecklistSuccess(
  checklist: Checklist
): CreateChecklistSuccessAction {
  return {
    type: CREATE_CHECKLIST_SUCCESS,
    checklist: { ...checklist, justAdded: true },
  }
}

export function cancelChecklist(): CancelChecklistAction {
  return {
    type: CANCEL_CHECKLIST,
  }
}

export function createChecklistError(
  error: string
): CreateChecklistErrorAction {
  return {
    type: CREATE_CHECKLIST_ERROR,
    error,
  }
}

export function deleteChecklistInit(
  checklistId: number
): DeleteChecklistInitAction {
  return {
    type: DELETE_CHECKLIST_INIT,
    checklistId,
  }
}

export function deleteChecklistSuccess(
  checklistId: number
): DeleteChecklistSuccessAction {
  return {
    type: DELETE_CHECKLIST_SUCCESS,
    checklistId,
  }
}

export function deleteChecklistError(
  error: string
): DeleteChecklistErrorAction {
  return {
    type: DELETE_CHECKLIST_ERROR,
    error,
  }
}

export function updateDueDateInit(
  requestId: number,
  dueDate: string
): UpdateDueDateInitAction {
  return {
    type: UPDATE_DUE_DATE_INIT,
    requestId,
    dueDate,
  }
}

export function updateDueDateSuccess(
  request: Object
): UpdateDueDateSuccessAction {
  return {
    type: UPDATE_DUE_DATE_SUCCESS,
    request: { ...request },
  }
}

export function updateDueDateError(error: string): UpdateDueDateErrorAction {
  return {
    type: UPDATE_DUE_DATE_ERROR,
    error,
  }
}

export function openChecklistsPopUp() {
  return {
    type: REQUEST_VIEW_SHOW_MODAL_CHECKLISTS,
  }
}

export function updateLabels(labels) {
  return {
    type: REQUEST_VIEW_UPDATE_LABELS,
    labels,
  }
}

export function updateInfo(id, params) {
  return {
    type: REQUEST_VIEW_UPDATE_INFO,
    params,
    id,
  }
}

export function openModal(modal = null) {
  return {
    type: REQUEST_VIEW_SHOW_MODAL,
    modal,
  }
}

export function hideModal() {
  return { type: REQUEST_VIEW_HIDE_MODAL }
}

export function openMassEditModal(callbackOnUpdate = null) {
  return { type: REQUEST_VIEW_OPEN_MASS_EDIT_MODAL, callbackOnUpdate }
}

export function initRequest(id, outbound) {
  return {
    type: REQUEST_VIEW_INITIATING,
    id,
    outbound,
  }
}

export function updateBody(id, data) {
  return {
    type: REQUEST_VIEW_UPDATE_BODY,
    id,
    data,
  }
}

export function updateFiles(
  id: number,
  outbound?: string,
  files: Array<Object>
) {
  return {
    type: REQUEST_VIEW_UPDATE_FILES,
    id,
    outbound,
    files,
  }
}
