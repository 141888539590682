// @flow

import React, { useEffect, useState, useRef } from 'react'
import classnames from 'classnames'
import type { Node } from 'react'

import {
  DEFAULT_MIN_SEARCH_LENGTH,
  DEFAULT_PAGE_SIZE,
  DROPDOWN_MENU_TYPE,
  EMBEDDED_MENU_TYPE,
} from './NewSelectAsync.constants'
import { useDebounce } from '../../hooks'
import Button from '../Button'
import NewSelectAsyncMenu from './NewSelectAsyncMenu'

import styles from './NewSelectAsync.module.scss'
import { DEFAULT_LIST_SIZE } from '../NewSelectSimple/NewSelectSimple.constants'

type Props = {
  api: Function,
  avatarKey?: string,
  buttonClass?: string,
  buttonName?: string,
  checkAllSelected?: boolean => void,
  className?: string,
  delay?: number,
  disabled: boolean,
  dynamicParams?: Object,
  emptyMenu?: Node,
  error?: string,
  getDescription?: Object => string,
  getLabel: Object => string,
  getMeta?: (Object, boolean) => Node,
  getSelectedLabel: Object => string,
  idField?: string,
  initialItems?: Array<Object>,
  isAllSelected?: (Array<Object>) => boolean,
  isMulti: boolean,
  isTop?: boolean,
  listSize?: number,
  menuClass?: string,
  minSearchLength: number,
  noAll?: boolean,
  onClear?: Object => void,
  onClick: Object => void,
  onClickAll?: (boolean, boolean) => void,
  onClose?: (val: string) => void,
  pageSize: number,
  permanentParams?: Object,
  placeholder: string,
  preloadAll?: boolean,
  searchKey?: string,
  selectedItems: Array<Object>,
  setSelectedItems?: (Array<Object>) => void,
  style?: Object,
  toggleMenuCb?: boolean => void,
  view: 'embedded' | 'dropdown',
}

const NewSelectAsync = (props: Props): Node => {
  const {
    api,
    avatarKey,
    pageSize,
    selectedItems,
    placeholder,
    searchKey,
    view,
    style,
    className,
    idField,
    isMulti,
    isTop,
    listSize,
    permanentParams,
    error,
    buttonClass,
    disabled,
    dynamicParams,
    buttonName,
    emptyMenu,
    menuClass,
    minSearchLength,
    isAllSelected,
    preloadAll,
    delay = 300,
    noAll,
    initialItems,
  } = props

  const [items, setItems] = useState([])
  const [meta, setMeta] = useState(null)
  const [loading, setLoading] = useState(false)
  const [initiating, setInitiating] = useState(true)
  const [value, setValue] = useState('')
  const [page, setPage] = useState(1)
  const [params, setParams] = useState(permanentParams || {})
  const [menuIsOpen, setMenuOpen] = useState(false)
  const [position, setPosition] = useState('bottom')
  const [isAllLoading, setAllLoading] = useState(false)
  const [isAllLoaded, setAllLoaded] = useState(false)

  const selectRef = useRef()

  const debouncedValue = useDebounce(value, delay)

  const getData = isAll => {
    api({
      ...params,
      page_size: isAll ? 100500 : pageSize,
      page: 1,
      ...dynamicParams,
    }).then(data => {
      setItems([...(initialItems || []), ...data.results.objects])
      setMeta(data.meta)

      if (props.setMetaCount && data.meta && pageSize) {
        props.setMetaCount(
          data.meta.count + (initialItems ? initialItems.length : 0)
        )
      }

      setInitiating(false)

      if (props.setSelectedItems && isAll) {
        props.setSelectedItems([
          ...(initialItems || []),
          ...data.results.objects,
        ])

        setAllLoaded(true)
        setAllLoading(false)

        if (props.onClickAll) {
          props.onClickAll(false, true)
        }
      }
    })
  }

  const addData = () => {
    api({ ...params, page_size: pageSize, page, ...dynamicParams }).then(
      data => {
        setItems(items.concat(data.results.objects))
        setMeta(data.meta)
        setLoading(false)
      }
    )
  }

  useEffect(() => {
    if (!initiating && !loading) {
      setInitiating(true)
      setMenuOpen(false)
      setValue('')
      setPage(1)
    }
  }, [dynamicParams])

  useEffect(() => {
    if (isAllLoading) {
      setInitiating(true)
    }
  }, [isAllLoading])

  useEffect(() => {
    if (preloadAll && !isAllLoaded) {
      setAllLoading(true)

      return
    }

    if (!menuIsOpen && view === DROPDOWN_MENU_TYPE) {
      return
    }

    if (initiating) {
      getData()
    }

    return () => {
      onInputChange('')
    }
  }, [menuIsOpen, view])

  useEffect(() => {
    if (props.toggleMenuCb) {
      props.toggleMenuCb(menuIsOpen)
    }
  }, [menuIsOpen])

  useEffect(() => {
    if (!loading && !initiating) {
      setLoading(true)
      addData()
    }
  }, [page])

  useEffect(() => {
    if (!loading && !initiating) {
      setInitiating(true)

      getData()
    }
  }, [params])

  useEffect(() => {
    if (isAllLoading) {
      setMenuOpen(false)

      getData(true)
    }
  }, [isAllLoading])

  useEffect(() => {
    if (disabled || !searchKey) {
      return
    }

    if (debouncedValue && debouncedValue.length < minSearchLength) {
      return
    }

    if (isAllLoaded) {
      return
    }

    setPage(1)
    setParams({ ...params, [searchKey]: debouncedValue || undefined })
  }, [debouncedValue])

  useEffect(() => {
    if (props.checkAllSelected && props.isAllSelected) {
      const isAllItemsSelected = props.isAllSelected(selectedItems)

      props.checkAllSelected(isAllItemsSelected)
    }
  }, [selectedItems])

  const onInputChange = value => setValue(value)

  const handleMenuScroll = e => {
    if (loading || (meta && meta.curr_page === meta.page_count)) {
      return
    }

    const { scrollHeight, offsetHeight, scrollTop } = e.target
    const scrolled = offsetHeight + scrollTop

    if (scrollHeight / 2 <= scrolled) {
      setPage(meta ? meta.curr_page + 1 : 1)
    }
  }

  const handleToggle = () => {
    if (!menuIsOpen && selectRef.current) {
      const isBottom =
        !isTop &&
        window.innerHeight - selectRef.current.getBoundingClientRect().bottom >
          listSize * 40 + 80

      setPosition(isBottom ? 'bottom' : 'top')
    }

    setMenuOpen(!menuIsOpen)
  }

  const handleClick = value => () => {
    props.onClick(value)

    if (!isMulti) {
      setMenuOpen(false)
    }
  }

  const isEmbedded = view === EMBEDDED_MENU_TYPE

  const selectClass = classnames(styles.select, className, {
    [styles.embedded]: isEmbedded,
  })

  const handleClickOut = () => {
    setMenuOpen(false)

    if (props.onClose) {
      props.onClose(value)
    }
  }

  return (
    <div className={selectClass} style={style} ref={selectRef}>
      {!isEmbedded && (
        <>
          <Button.NewSelect
            buttonClass={buttonClass}
            error={!!error}
            name={buttonName}
            disabled={disabled || isAllLoading}
            working={isAllLoading}
            placeholder={placeholder}
            selectedItems={selectedItems}
            getMeta={props.getMeta}
            getSelectedLabel={props.getSelectedLabel}
            menuIsOpen={menuIsOpen}
            onClick={handleToggle}
            onClear={props.onClear}
          />
          {typeof error === 'string' && (
            <span className={styles.errorText}>{error}</span>
          )}
        </>
      )}
      {(isEmbedded || menuIsOpen) && (
        <NewSelectAsyncMenu
          handleClickOut={handleClickOut}
          debouncedValue={debouncedValue}
          className={menuClass}
          position={position}
          searchKey={searchKey}
          isEmbedded={isEmbedded}
          placeholder={placeholder}
          selectedItems={selectedItems}
          initiating={initiating}
          listSize={listSize}
          loading={loading}
          items={items}
          idField={idField}
          minSearchLength={minSearchLength}
          noAll={noAll}
          avatarKey={avatarKey}
          getLabel={props.getLabel}
          getDescription={props.getDescription}
          value={value}
          getMeta={props.getMeta}
          setValue={setValue}
          emptyMenu={emptyMenu}
          isAllSelected={isAllSelected}
          isAllLoading={isAllLoading}
          isAllLoaded={isAllLoaded}
          setSelectedItems={props.setSelectedItems}
          setAllLoading={setAllLoading}
          setMenuOpen={setMenuOpen}
          onClickAll={props.onClickAll}
          onScroll={handleMenuScroll}
          onClick={handleClick}
        />
      )}
    </div>
  )
}

NewSelectAsync.defaultProps = {
  isMulti: false,
  listSize: DEFAULT_LIST_SIZE,
  pageSize: DEFAULT_PAGE_SIZE,
  view: EMBEDDED_MENU_TYPE,
  disabled: false,
  minSearchLength: DEFAULT_MIN_SEARCH_LENGTH,
}

export default NewSelectAsync
