//@flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import ModalBody from '../../../components/Modal/ModalBody'
import ModalCloseButton from '../../../components/Modal/ModalCloseButton'
import ModalHead from '../../../components/Modal/ModalHead'
import ModalButtons from '../../../components/Modal/ModalButtons'
import Button from '../../../components/Button'
import { useOverflow } from '../../../hooks'

import RequestRequests from '../RequestRequests'

const RelatedRequestsModal = (props): Node => {
  const { requestId, showMassEditButton } = props
  const { t } = useTranslation('Request')

  useOverflow()

  return (
    <>
      <ModalHead title={t('RelatedRequestsTitle')} />
      <ModalCloseButton onClose={props.onClose} />
      <ModalBody style={{ maxHeight: '604px' }}>
        <RequestRequests
          requests
          viewOnly
          params={{ request: requestId }}
          showMassEditButton={showMassEditButton}
          init={{ request: requestId }}
        />
      </ModalBody>
      <ModalButtons>
        <Button.Save onClick={props.onClose}>{t('Common:Ok')}</Button.Save>
      </ModalButtons>
    </>
  )
}

export default RelatedRequestsModal
