// @flow

import React from 'react'

import RequestList from '../../../components/Request/RequestList'

const RequestRequests = props => {
  const { init, params, showMassEditButton, viewOnly } = props

  return (
    <RequestList
      params={params}
      init={init}
      showMassEditButton={showMassEditButton}
      tabContext='request'
      viewOnly={viewOnly}
    />
  )
}

export default RequestRequests
