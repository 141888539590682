// @flow

import { clone, pull, map } from 'lodash-es'

import * as actions from './RequestList.actionTypes'

const initialState = {
  requests: null,
  selected: [],
  initiated: false,
  working: false,
  meta: null,
  permissions: null,
  modal: null,
  error: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.REQUEST_LIST_RESET_INITIAL_STATE:
      return initialState
    case actions.REQUEST_LIST_INITIATE:
      return { ...initialState, working: true }
    case actions.REQUEST_LIST_INITIATED:
      return {
        ...state,
        initiated: true,
        permissions: action.permissions,
        requests: action.results.objects,
        working: false,
      }
    case actions.REQUEST_LIST_EXTRA_INITIATED:
      return {
        ...state,
        requests: action.withExtra,
      }
    case actions.REQUEST_LIST_META_INITIATED:
      return {
        ...state,
        working: false,
        meta: action.meta,
      }
    case actions.REQUEST_LIST_TOGGLE: {
      const { id } = action

      let selected = clone(state.selected)

      if (selected.includes(id)) {
        selected = pull(selected, id)
      } else {
        selected.push(id)
      }

      return {
        ...state,
        selected,
      }
    }
    case actions.REQUEST_LIST_TOGGLE_ALL: {
      const { requests } = state
      const activeRequests = requests.filter(
        r => !r.archived || action.isRequestsPage
      )

      const currentRequests =
        action.tabContext === 'request' ? requests : activeRequests

      let selected = []

      if (state.selected.length !== currentRequests.length) {
        selected = map(currentRequests, request => request.id)
      }

      return {
        ...state,
        selected,
      }
    }
    case actions.REQUEST_LIST_SHOW_MODAL:
      return {
        ...state,
        modal: action.modal,
      }
    case actions.REQUEST_LIST_HIDE_MODAL:
      return {
        ...state,
        modal: null,
      }
    case actions.REQUEST_LIST_LOAD:
      return {
        ...state,
        working: true,
        modal: null,
        selected: [],
      }
    case actions.REQUEST_LIST_MASS_UPDATE:
      return {
        ...state,
        working: true,
        modal: null,
        selected: [],
      }
    case actions.REQUEST_LIST_UPDATED:
      return {
        ...state,
        working: false,
        initiated: true,
        permissions: action.permissions,
        requests: action.results.objects,
      }
    case actions.REQUEST_LIST_EXTRA_UPDATED:
      return {
        ...state,
        working: false,
        requests: action.withExtra,
      }
    case actions.REQUEST_LIST_META_UPDATED:
      return {
        ...state,
        working: false,
        initiated: true,
        meta: action.meta,
      }
    case actions.REQUEST_LIST_ERROR:
      return {
        ...state,
        error: action.error.message,
      }
    default:
      return state
  }
}
