// @flow

import React from 'react'
import classnames from 'classnames'
import type { Node } from 'react'

import Button from './Button'

import PointerIcon from '../../static/icons/hint-poiner.svg'

type Props = {
  boxClassName?: string,
  className?: string,
  link: string,
  noArrow?: boolean,
  onClick?: () => void,
  onClose?: () => void,
  text: string,
}

const Warning = (props: Props): Node => {
  const { className, link, onClick, text, boxClassName, noArrow } = props

  const alertClass = classnames(
    'alert alert--small alert--warning alert--block alert--center alert--top',
    className,
    { withLink: link }
  )

  const boxClass = classnames('alert--warning-box', boxClassName)

  return (
    <div className={boxClass}>
      <div className={alertClass}>
        {!!props.onClose && <Button.Close onClick={props.onClose} />}
        <p className='alert__text'>{text}</p>
        {link && onClick && (
          <p className='alert__text alert__link' onClick={onClick}>
            {link}
          </p>
        )}
      </div>
      {!noArrow && <PointerIcon />}
    </div>
  )
}

Warning.defaultProps = {
  className: '',
}

export default Warning
